<script context="module">
  import { WIDTHS, HEIGHTS, BACKDROPS } from '../_lib/BaseButton/utils'

  export { WIDTHS, HEIGHTS, BACKDROPS }
</script>

<script>
  import BaseButton from '../_lib/BaseButton'

  import { getClass } from './utils'

  export let backdropColor = undefined

  $: className = getClass({ backdropColor, className: $$props.class || '' })
</script>

<BaseButton on:click {...$$restProps} class={className}>
  <slot />
</BaseButton>
