<script>
  import { GridContainer, GridColumn, WidgetHeader, WidgetFooter } from '@qlean/ui-kit-web-svelte'

  export let title
  export let titleTag
  export let subTitle
  export let isTitleLarge
  export let withTopMargin
  export let withBottomMargin
  export let cN
  // FIXME: костыль для зимних окон
  export let swapHeaderAndForm
</script>

{#if !swapHeaderAndForm}
  <WidgetHeader {title} {titleTag} {subTitle} {isTitleLarge} withMargin={withTopMargin} />
{/if}
<GridContainer>
  <GridColumn class={cN('form__wrap')}>
    <slot />
  </GridColumn>
</GridContainer>
<WidgetFooter withMargin={withBottomMargin} />
