<script>
  import classNames from 'classnames/bind'
  import { Radio } from '@qlean/ui-kit-web-svelte'

  import css from './styles.scss'

  const cN = classNames.bind(css)
</script>

<Radio {...$$restProps} class={cN('wrapper')} on:change />
