import axios from 'axios'

type TFormValues = {
  phone: string
  name?: string
  comment?: string
}

export async function sendCallbackForm(submitRootPath: string, values: TFormValues) {
  const payload = {
    phone: values.phone,
    name: values.name || 'Неизвестно',
    comment: values.comment || '',
  }

  const { data } = await axios.post(`${submitRootPath}/http/sendCallbackForm`, payload)

  return data
}
