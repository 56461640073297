<script>
  import { createForm } from 'final-form'
  import { ButtonPrimary, Input } from '@qlean/ui-kit-web-svelte'

  import Field from '../../../components/Field'
  import {
    setFormattedPhone,
    onFocusPhone,
    validatePhone,
    validateOfficeArea,
  } from '../../../utils'

  export let onSuccess
  export let onUnknownError
  export let onChangeState = () => {}

  export let buttonTitle
  export let initialValues
  export let showPhone = true
  export let fields
  export let cN

  let isSubmitting = false

  async function submit(values, _, callback) {
    onChangeState()
    isSubmitting = true

    try {
      callback()
      await onSuccess(values)
    } catch (err) {
      callback()
      onUnknownError(err)
    }

    isSubmitting = false
  }

  function validate({ area, phone }) {
    let errors = { ...validatePhone(phone) }

    if (area) {
      errors = { ...errors, ...validateOfficeArea(area) }
    }

    return errors
  }

  const form = createForm({
    initialValues,
    onSubmit: submit,
    validate,
    validateOnBlur: true,
  })
</script>

<form class={cN('form__column')} on:submit|preventDefault|stopPropagation={form.submit}>
  {#if fields.area}
    <Field let:input let:meta name="area" {form}>
      <div class={cN('input-wrap')}>
        <Input
          size="m"
          withError={meta.touched && meta.error !== undefined && input.value === meta.error.value && meta.error.message}
          type="text"
          name="area"
          placeholder={!input.value ? 'Площадь офиса, м2' : ''}
          value={input.value}
          disabled={isSubmitting}
          on:input={(e) => input.change(e.target.value)}
          on:focus={(e) => input.focus()}
          on:blur={(e) => input.blur()}
          autocomplete="off" />
      </div>
    </Field>
  {/if}

  {#if fields.name}
    <Field let:input let:meta name="name" {form}>
      <div class={cN('input-wrap')}>
        <Input
          size="m"
          withError={meta.touched && meta.error !== undefined && input.value === meta.error.value && meta.error.message}
          type="text"
          name="name"
          placeholder={!input.value ? 'Имя' : ''}
          value={input.value}
          disabled={isSubmitting}
          on:input={(e) => input.change(e.target.value)}
          on:focus={(e) => input.focus()}
          on:blur={(e) => input.blur()}
          autocomplete="off" />
      </div>
    </Field>
  {/if}

  {#if showPhone}
    <Field let:input let:meta name="phone" {form}>
      <div class={cN('input-wrap')}>
        <Input
          id="inputNumberArea"
          size={Input.SIZES.MEDIUM}
          withError={meta.touched && meta.error !== undefined && input.value === meta.error.value && meta.error.message}
          maxlength="18"
          type="tel"
          name="phone"
          placeholder="+7 (___) ___-__-__"
          value={input.value}
          disabled={isSubmitting}
          on:input={(e) => setFormattedPhone(input, e.target.value)}
          on:focus={(e) => onFocusPhone(input, e.target.value)}
          on:blur={(e) => input.blur()}
          autocomplete="off" />
      </div>
    </Field>
  {/if}

  <div class={cN('input-wrap', 'button-wrap')}>
    <ButtonPrimary id="getCountButton" {isSubmitting} type="submit">{buttonTitle}</ButtonPrimary>
  </div>
</form>
