<script>
  import { Icon } from '@qlean/ui-kit-web-svelte'
  export let disabled = false
  export let processing
</script>

<button on:click disabled={disabled || processing} class={$$props.class}>
  {#if processing}
    <Icon key={Icon.KEYS.SMALL} name={Icon.NAMES.loader} />
  {:else}
    <slot />
  {/if}
</button>
