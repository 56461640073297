<script>
  import { Separator, Caption, GridContainer, GridColumn, Picture } from '@qlean/ui-kit-web-svelte'
  import { Bus } from '@qlean/web-core'
  import axios from 'axios'

  import OtpVerification from './components/SellGoodsShortWebOtpVerification'
  import FirstStep from './FirstStep'
  import Disclaimer from '../../components/Disclaimer'

  export let defaultValues
  export let productSlug
  export let pageSlug
  export let submitRootPath
  export let fields
  export let texts
  export let cN
  export let region
  export let promocode

  const { disclaimer, title, subTitle, buttonTitle, image, recallText, recallNumber } = texts

  let initialValues = { ...defaultValues }
  let showVerificationFields = false
  let formValues = { ...initialValues }
  let ids
  let commonError = null

  const COMMON_ERROR_MSG = 'Сервис временно недоступен, попробуйте позже'

  async function submitData(values, ids) {
    const { data } = await axios.post(`${submitRootPath}/http/form/submit`, {
      productSlug,
      pageSlug,
      values,
      ids,
      region,
      promocode,
    })

    return data
  }

  async function onSuccessPhone(values) {
    resetCommonError()
    Bus.emit('FORM_CREATE', { ids })

    try {
      if (defaultValues.phone === undefined) {
        formValues = { ...values }
        showVerificationFields = true
      } else {
        ids = await submitData(values)
        Bus.emit('FORM_FINISH', { ids })
      }
    } catch (err) {
      commonError = COMMON_ERROR_MSG
    }
  }
  async function onSuccessCode() {
    resetCommonError()

    try {
      await submitData(formValues, ids)

      Bus.emit('FORM_FINISH', { ids })
    } catch (err) {
      commonError = COMMON_ERROR_MSG
    }
  }

  function hideVerification() {
    resetCommonError()

    initialValues = formValues
    showVerificationFields = false
  }

  function onUnknownError(err) {
    try {
      // @fixme resolve optional chaining for svelte
      if (err.response.status === 429) {
        commonError = 'Слишком частые запросы SMS'
      } else {
        // @todo: log it
        commonError = COMMON_ERROR_MSG
      }
    } catch (e) {
      commonError = COMMON_ERROR_MSG
    }
  }

  function resetCommonError() {
    commonError = null
  }
</script>

<GridContainer>
  <GridColumn widthMiddle={6} class={cN('form__wrap')}>
    <div class={cN('form, form--white')}>
      <div class={cN('form__row')}>
        {#if !showVerificationFields}
          <FirstStep
            onSuccess={onSuccessPhone}
            {onUnknownError}
            onChangeState={resetCommonError}
            {initialValues}
            {buttonTitle}
            {fields}
            {texts}
            {cN} />
        {:else}
          <OtpVerification
            onSuccess={onSuccessCode}
            {onUnknownError}
            onChangeState={resetCommonError}
            onRequestHide={hideVerification}
            canRequestHide={defaultValues.phone === undefined}
            phone={formValues.phone}
            {submitRootPath}
            {cN} />
        {/if}
      </div>
      {#if commonError !== null}
        <Separator key={10} size="medium" />
        <Caption class={cN('form__common-error')}>{commonError}</Caption>
      {/if}

      <div class={cN('sell-goods-short-form__wrapper')}>
        <Disclaimer {disclaimer} {cN} />
      </div>

      <div class={cN('sell-goods-short-form__wrapper')}>
        <span>{recallText}</span>
        <a
          href={`tel:${recallNumber}`}
          class={cN('sell-goods-short-form__recallNumber')}>{recallNumber}</a>
      </div>
    </div>
  </GridColumn>
</GridContainer>
