import masker from 'vanilla-masker'

export function validatePhone<T extends string = string>(
  phone: T
): TValidationResult<{ phone: T }> {
  const errors: TValidationResult<{ phone: T }> = {}

  if (!(phone && phone.replace(/\D/g, '').match(/^7(9+([0-9]){9})$/))) {
    errors.phone = { value: phone, message: 'Неверный формат номера телефона' }
  }

  return errors
}

const availableNotNums = {
  ' ': [2, 8, 12, 15],
  '(': [3],
  ')': [7],
  '-': [12, 15],
}
function returnNotNum(initial: string, cropped: string): string {
  const croppedIdx = cropped.length
  const croppedSymbol = initial[croppedIdx]

  const acceptableIndexes = availableNotNums[croppedSymbol]

  if (acceptableIndexes !== undefined && acceptableIndexes.includes(croppedIdx)) {
    return cropped + croppedSymbol
  }

  return cropped
}

export function formatPhone(value: string): string {
  let nextValue = value
  nextValue = nextValue[0] === '8' ? nextValue.slice(1) : nextValue
  nextValue = nextValue.replace(/\D/g, '')

  let preMasked
  let toMask
  if (nextValue.length === 0 || value[0] !== '+') {
    preMasked = `+7 (${nextValue}`
    toMask = preMasked
  } else {
    preMasked = value
    toMask = nextValue
  }

  nextValue = masker.toPattern(toMask, '+9 (999) 999 99 99')

  let didReturn
  do {
    const tempValue = returnNotNum(preMasked, nextValue)
    didReturn = tempValue !== nextValue

    nextValue = tempValue
  } while (didReturn)

  return nextValue
}

export function setFormattedPhone(input, value: string): void {
  const nextValue = formatPhone(value)

  if (nextValue !== value) {
    // formatPhone может убрать последний символ (не числовой) и тогда значение в инпуте и новое - будут одинаковыми
    // перериcовка при этом не сработает и в нативном поле останется неизмененое значение
    // поэтому нужно принудительно изменить значение
    input.change(`${nextValue} `)
  }
  input.change(nextValue)
}

export function onFocusPhone(input, value): void {
  if (value.length === 0) {
    setFormattedPhone(input, value)
  }

  input.focus()
}
