<script>
  import { Caption, Link, Separator, ContentHandler } from '@qlean/ui-kit-web-svelte'

  export let disclaimer = undefined
  export let cN

  const linksUnits = Object.entries(disclaimer.vars).reduce((nextUnits, [key, { href, text }]) => {
    return {
      ...nextUnits,
      [key]: {
        props: { href },
        text,
      },
    }
  }, {})
</script>

{#if disclaimer !== undefined}
  <Separator key={15} size={Separator.SIZES.SMALLEST} />
  <ContentHandler
    baseText={{ Component: Caption, props: { class: cN('disclaimer') } }}
    links={{ Component: Link, props: { target: '_blank', rank: Link.RANKS[2], backdropColor: Link.BACKDROPS.LIGHT }, units: linksUnits }}
    content={disclaimer.template} />
{/if}
