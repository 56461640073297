<script>
  import { createForm } from 'final-form'
  import ButtonPrimary from '../components/Buttons/ButtonPrimary'
  import Input from '../components/Input'
  import { SIZES as INPUTSIZES } from '../components/Input/utils'
  import { WIDTHS } from '../components/Buttons/_lib/BaseButton'

  import Field from '../../../components/Field'
  import { setFormattedPhone, onFocusPhone, validatePhone, formatPhone } from '../../../utils'

  export let onSuccess
  export let onUnknownError
  export let onChangeState = () => {}
  export let disabledPhone = false
  export let initialValues
  export let texts
  export let cN

  let isSubmitting = false

  const {
    disclaimer,
    title,
    title2,
    subTitle,
    buttonTitle,
    image,
    recallText,
    recallNumber,
  } = texts

  async function submit(values, _, callback) {
    onChangeState()
    isSubmitting = true

    try {
      callback()
      await onSuccess(values)
    } catch (err) {
      callback()
      onUnknownError(err)
    }

    isSubmitting = false
  }

  function validate({ phone }) {
    return { ...validatePhone(phone) }
  }

  if (initialValues && initialValues.phone) {
    initialValues.phone = formatPhone(initialValues.phone)
  }

  const form = createForm({
    initialValues,
    onSubmit: submit,
    validate,
    validateOnBlur: true,
  })
</script>

<form class={cN('lamoda__column')} on:submit|preventDefault|stopPropagation={form.submit}>
  <div><span class={cN('lamoda__header')}>{title}</span></div>
  <div class={cN('lamoda__wrapper')}>
    <span class={cN('lamoda__header', 'lamoda__header--lamodaRed')}>{title2}</span>
  </div>
  <div class={cN('lamoda__wrapper')}><span class={cN('lamoda__subheader')}>{subTitle}</span></div>

  <div class={cN('lamoda__wrapper')}>
    <Field let:input let:meta name="phone" {form}>
      <div class={cN('input-wrap')}>
        <Input
          size={INPUTSIZES.SMALL}
          withError={meta.touched && meta.error !== undefined && input.value === meta.error.value && meta.error.message}
          maxlength="18"
          type="tel"
          name="phone"
          placeholder="+7 (000) 000 00 00"
          value={input.value}
          disabled={disabledPhone || isSubmitting}
          on:input={(e) => setFormattedPhone(input, e.target.value)}
          on:focus={(e) => onFocusPhone(input, e.target.value)}
          on:blur={(e) => input.blur()}
          autocomplete="off" />
      </div>
    </Field>
  </div>
  <div class={cN('lamoda__button-wrap')}>
    <ButtonPrimary {isSubmitting} type="submit" width={WIDTHS.MEDIUM}>{buttonTitle}</ButtonPrimary>
  </div>
</form>
