import classNames from 'classnames/bind'

import css from '../style.scss'
// @ts-ignore
import { TBaseProps } from '../_lib/types'
import { BACKDROPS } from '../_lib/BaseButton/utils'

const cN = classNames.bind(css)
const blockName = 'button--primary'

export type TProps = TBaseProps & { backdropColor: ValueOf<typeof BACKDROPS> }

export function getClass({ backdropColor, className }: TProps & { className: string }): string {
  // @ts-ignore
  return cN(
    blockName,
    {
      [`${blockName}-on-dark`]: backdropColor === BACKDROPS.DARK,
      [`${blockName}-on-light`]: backdropColor === BACKDROPS.LIGHT,
    },
    className
  )
}
