<script>
  import { createForm } from 'final-form'
  import { Separator, ButtonPrimary, Input, Text } from '@qlean/ui-kit-web-svelte'
  import { Bus } from '@qlean/web-core'

  import { setFormattedPhone, onFocusPhone, validatePhone, sendCallbackForm } from '../../utils'
  import CommonWrapper from '../../components/CommonWrapper'
  import Disclaimer from '../../components/Disclaimer'
  import Field from '../../components/Field'

  export let onChangeState = () => {}
  export let texts

  export let initialValues
  export let cN
  export let commonError
  export let submitRootPath

  const COMMON_ERROR_MSG = 'Сервис временно недоступен, попробуйте позже'

  const { buttonTitle, title, disclaimer } = texts

  let isSubmitting = false

  async function sendForm(values) {
    await sendCallbackForm(submitRootPath, values)

    Bus.emit('CALLBACK_FORM_SENT')
  }

  async function submit(values, _, callback) {
    onChangeState()
    isSubmitting = true

    try {
      callback()
      await sendForm(values)
    } catch (err) {
      callback()
      onUnknownError(err)
    }

    isSubmitting = false
  }

  function validate({ phone, comment, name }) {
    const errors = { ...validatePhone(phone) }
    if ((comment || '').trim().length < 5) {
      errors.comment = { value: comment, message: 'Минимум 5 символов.' }
    }
    return errors
  }

  function onUnknownError(err) {
    try {
      // @fixme resolve optional chaining for svelte
      if (err.response.status === 429) {
        commonError = 'Слишком частые запросы SMS'
      } else {
        // @todo: log it
        commonError = COMMON_ERROR_MSG
      }
    } catch (e) {
      commonError = COMMON_ERROR_MSG
    }
  }

  const form = createForm({
    initialValues,
    onSubmit: submit,
    validate,
    validateOnBlur: true,
  })
</script>

<CommonWrapper {title} {cN} {...$$restProps}>
  <div class={cN('form')}>
    <Separator key={10} size="medium" />
    <div class={cN('form-callback__row')}>
      <Separator key={10} size="medium" direction="horizontal" />
      <form
        class={cN('form-callback__column')}
        on:submit|preventDefault|stopPropagation={form.submit}>
        <Separator key={10} size="medium" direction="horizontal" />
        <Text>Ваш номер телефона</Text>
        <Separator key={2} size="medium" direction="horizontal" />
        <Field let:input let:meta name="phone" {form}>
          <div class={cN('input-wrap')}>
            <Input
              size={Input.SIZES.MEDIUM}
              withError={meta.touched && meta.error !== undefined && input.value === meta.error.value && meta.error.message}
              maxlength="18"
              name="phone"
              placeholder="+7 (___) ___-__-__"
              value={input.value}
              disabled={isSubmitting}
              on:input={(e) => setFormattedPhone(input, e.target.value)}
              on:focus={(e) => onFocusPhone(input, e.target.value)}
              on:blur={(e) => input.blur()}
              autocomplete="off" />
          </div>
        </Field>
        <Separator key={10} size="medium" />
        <Separator key={10} size="medium" direction="horizontal" />
        <Text>Ваше имя</Text>
        <Separator key={2} size="medium" direction="horizontal" />
        <Field let:input name="name" {form}>
          <div class={cN('input-wrap')}>
            <Input
              size={Input.SIZES.MEDIUM}
              name="name"
              label="Ваше имя"
              value={input.value}
              disabled={isSubmitting}
              on:change={({ target: { value } }) => {
                input.change(value)
              }}
              on:blur={(e) => input.blur()} />
          </div>
        </Field>
        <Separator key={10} size="medium" />
        <Separator key={10} size="medium" direction="horizontal" />
        <Text>Комментарий</Text>
        <Separator key={2} size="medium" direction="horizontal" />
        <Field let:input let:meta name="comment" {form}>
          <div class={cN('input-wrap')}>
            <Input
              size={Input.SIZES.MEDIUM}
              name="comment"
              label="Комментарий"
              withError={meta.touched && meta.error !== undefined && input.value === meta.error.value && meta.error.message}
              value={input.value}
              disabled={isSubmitting}
              minLength="5"
              on:change={({ target: { value } }) => {
                input.change(value)
              }}
              on:blur={(e) => input.blur()} />
          </div>
        </Field>
        <Separator key={10} size="medium" />
        <Separator key={10} size="medium" />
        <ButtonPrimary {isSubmitting} type="submit">{buttonTitle}</ButtonPrimary>
        <Disclaimer {disclaimer} {cN} />
        <Separator key={10} size="medium" />
      </form>
      <Separator key={10} size="medium" direction="horizontal" />
    </div>
  </div>
</CommonWrapper>
