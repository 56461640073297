export { validatePhone, formatPhone, onFocusPhone, setFormattedPhone } from './phone'
export { requestOtp, verifyOtp } from './otp'
export { sendCallbackForm } from './callback'

export function validateArea<T extends string = string>(area: T): TValidationResult<{ area: T }> {
  const errors: TValidationResult<{ area: T }> = {}

  if (!area || /[^\d]+/.test(area) || +area > 1000) {
    errors.area = { value: area, message: 'Неверная площадь дома' }
  }

  return errors
}

export function validateOfficeArea<T extends string = string>(
  area: T
): TValidationResult<{ area: T }> {
  const errors: TValidationResult<{ area: T }> = {}

  if (!area || /[^\d]+/.test(area) || +area > 10000) {
    errors.area = { value: area, message: 'Неверная площадь офиса' }
  }

  return errors
}
