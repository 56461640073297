<script context="module">
  import { WIDTHS, HEIGHTS } from './utils'

  export { WIDTHS, HEIGHTS }
</script>

<script>
  import Button from './Button'
  import Link from './Link'
  import { getClass } from './utils'

  export let isLink = false
  export let rounded = false
  export let floating = false
  export let isSubmitting = false
  export let height = HEIGHTS.MEDIUM
  export let width = WIDTHS.WIDE

  $: className = getClass({
    width,
    height,
    rounded,
    floating,
    isProcessing: isSubmitting,
    className: $$props.class || '',
  })
</script>

{#if isLink}
  <Link {...$$restProps} class={className}>
    <slot />
  </Link>
{:else}
  <Button on:click {...$$restProps} class={className} processing={isSubmitting}>
    <slot />
  </Button>
{/if}
