<script>
  import { createForm } from 'final-form'
  import { ButtonPrimary, Input } from '@qlean/ui-kit-web-svelte'

  import Field from '../../../components/Field'
  import Radio from '../../../components/RadioField'
  import { setFormattedPhone, onFocusPhone, validatePhone } from '../../../utils'

  export let onSuccess
  export let onUnknownError
  export let onChangeState = () => {}

  export let buttonTitle
  export let initialValues
  export let showPhone = true
  export let fields
  export let cN

  let isSubmitting = false

  async function submit({ balconies, ...restValues }, _, callback) {
    onChangeState()
    isSubmitting = true

    try {
      callback()
      await onSuccess({ ...restValues, ...(balconies === '0' ? {} : { balconies }) })
    } catch (err) {
      callback()
      onUnknownError(err)
    }

    isSubmitting = false
  }

  function validate({ phone }) {
    return { ...validatePhone(phone) }
  }

  const form = createForm({
    initialValues,
    onSubmit: submit,
    validate,
    validateOnBlur: true,
  })
</script>

<form class={cN('form__column')} on:submit|preventDefault|stopPropagation={form.submit}>
  <Field let:input let:meta name="balconies" {form}>
    <div class={cN('input-wrap')}>
      <Radio
        name="balconies"
        label="Окна и балконы"
        value={1}
        disabled={isSubmitting}
        checked={input.value === 1}
        on:change={({ target: { value } }) => {
          input.change(value)
        }} />
    </div>
  </Field>

  <Field let:input let:meta name="balconies" {form}>
    <div class={cN('input-wrap')}>
      <Radio
        name="balconies"
        label="Только окна"
        value={0}
        disabled={isSubmitting}
        checked={input.value === 0}
        on:change={({ target: { value } }) => {
          input.change(value)
        }} />
    </div>
  </Field>
  {#if showPhone}
    <Field let:input let:meta name="phone" {form}>
      <div class={cN('input-wrap')}>
        <Input
          size={Input.SIZES.MEDIUM}
          withError={meta.touched && meta.error !== undefined && input.value === meta.error.value && meta.error.message}
          maxlength="18"
          type="tel"
          name="phone"
          placeholder="+7 (___) ___-__-__"
          value={input.value}
          disabled={isSubmitting}
          on:input={(e) => setFormattedPhone(input, e.target.value)}
          on:focus={(e) => onFocusPhone(input, e.target.value)}
          on:blur={(e) => input.blur()}
          autocomplete="off" />
      </div>
    </Field>
  {/if}

  <div class={cN('input-wrap', 'button-wrap')}>
    <ButtonPrimary {isSubmitting} type="submit">{buttonTitle}</ButtonPrimary>
  </div>
</form>
