<script>
  import classNames from 'classnames/bind'
  import { Text } from '@qlean/ui-kit-web-svelte'

  import css from './styles.scss'

  const cN = classNames.bind(css)

  export let options
  export let value
  export let onChange
  export let name

  $: index = options.findIndex(({ value: toCheck }) => toCheck === value)

  const increment = () => {
    onChange(value + 1)
  }
  const decrement = () => {
    onChange(value - 1)
  }
</script>

<div class={cN('wrapper')}>
  <button
    id={`${name}FormDecrement`}
    type="button"
    class={cN('decrement-button')}
    on:click={decrement}
    disabled={index === 0} />

  <Text class={cN('value')} id={`${name}FormText`}>{options[index].name}</Text>

  <button
    id={`${name}FormIncrement`}
    type="button"
    class={cN('increment-button')}
    on:click={increment}
    disabled={index === options.length - 1} />
</div>
