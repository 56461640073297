import classNames from 'classnames/bind'

import css from './style.scss'

const cN = classNames.bind(css)

export const SIZES = {
  SMALL: 's',
  MEDIUM: 'm',
}

const blockName = 'input-container'

export function getContainerClass({ className }: { className: string }) {
  // @ts-ignore
  return cN(blockName, className)
}

export function getWrapperClass({ disabled }: { disabled: boolean }) {
  const base = `${blockName}__wrapper`

  // @ts-ignore
  return cN(base, { [`${base}--disabled`]: disabled })
}

export function getInputClass({
  size,
  withError,
}: {
  size: ValueOf<typeof SIZES>
  withError: boolean
}) {
  const base = `${blockName}__input`

  // @ts-ignore
  return cN(base, { [`${base}--${size}`]: true, [`${base}--error`]: withError })
}

export function getPlaceholderClass({
  size,
  disabled,
}: {
  size: ValueOf<typeof SIZES>
  disabled: boolean
}) {
  const base = `${blockName}__placeholder`

  // @ts-ignore
  return cN(base, { [`${base}--${size}`]: true, [`${base}--disabled`]: disabled })
}

export function getErrorClass() {
  // @ts-ignore
  return cN({ [`${blockName}__error`]: true })
}

export function getPlaceholder(
  fullPlaceholder?: string,
  value?: string | number
): [string, string] | undefined {
  if (
    fullPlaceholder === undefined ||
    (value !== undefined && value.toString().length >= fullPlaceholder.length)
  ) {
    return
  }

  if (value === undefined) {
    return ['', fullPlaceholder]
  }

  const strValue = value.toString()

  return [strValue.replace(/\s/g, ' '), fullPlaceholder.slice(strValue.length).replace(/\s/g, ' ')]
}
