<script>
  import { Separator, Caption } from '@qlean/ui-kit-web-svelte'

  import CommonWrapper from '../../components/CommonWrapper'
  import OtpVerification from '../../components/OtpVerification'
  import Disclaimer from '../../components/Disclaimer'

  import Phone from './Phone'

  export let texts
  export let submitRootPath
  export let cN

  let initialValues = {}
  let formValues = { ...initialValues }
  let showVerificationFields = false
  let commonError = null

  const { titlePhone, titleOtpVerification, subTitle, buttonTitle, disclaimer } = texts

  const COMMON_ERROR_MSG = 'Сервис временно недоступен, попробуйте позже'

  async function onSuccessPhone(values) {
    resetCommonError()

    formValues = { ...values }
    showVerificationFields = true
  }
  async function onSuccessCode() {
    resetCommonError()
  }

  function hideVerification() {
    resetCommonError()

    initialValues = formValues
    showVerificationFields = false
  }

  function onUnknownError(err) {
    try {
      // @fixme resolve optional chaining for svelte
      if (err.response.status === 429) {
        commonError = 'Слишком частые запросы SMS'
      } else {
        // @todo: log it
        commonError = COMMON_ERROR_MSG
      }
    } catch (e) {
      commonError = COMMON_ERROR_MSG
    }
  }

  function resetCommonError() {
    commonError = null
  }
</script>

<CommonWrapper
  title={!showVerificationFields ? titlePhone : titleOtpVerification}
  {subTitle}
  {cN}
  {...$$restProps}>
  <div class={cN('form')}>
    <Separator key={10} size="medium" />
    {#if !showVerificationFields}
      <Phone
        onSuccess={onSuccessPhone}
        {onUnknownError}
        onChangeState={resetCommonError}
        {initialValues}
        {buttonTitle}
        {cN} />
    {:else}
      <OtpVerification
        onSuccess={onSuccessCode}
        {onUnknownError}
        onChangeState={resetCommonError}
        onRequestHide={hideVerification}
        canRequestHide={true}
        phone={formValues.phone}
        {submitRootPath}
        {cN} />
    {/if}
    {#if commonError !== null}
      <Separator key={10} size="medium" />
      <Caption class={cN('form__common-error')}>{commonError}</Caption>
    {/if}
    <Separator key={10} size="medium" />
  </div>

  {#if !showVerificationFields}
    <Disclaimer {disclaimer} {cN} />
  {/if}
</CommonWrapper>
