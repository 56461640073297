import axios from 'axios'
import { genCsrfToken } from '@qlean/nest-csrf-gen'

function formatPhoneRaw(string = ''): string {
  return `${string.replace(/\D/g, '').substr(0, 11)}`
}

export async function requestOtp(submitRootPath: string, phone: string): Promise<TIds> {
  const payload = {
    login: formatPhoneRaw(phone),

    send: 1, // @qlean/sso-authorize-sdk#SEND.CODE,
    userType: 1, // @qlean/sso-authorize-sdk#USER_TYPE.CUSTOMER,
    connection: 3, // @qlean/sso-authorize-sdk#CONNECTION.PASSWORDLESS,
  }
  const { userAgent } = window.navigator
  const csrfToken = genCsrfToken({ userAgent, payload })

  const { data } = await axios.post(`${submitRootPath}/http/requestotp`, {
    ...payload,
    userAgent,
    csrfToken,
  })

  return data
}

export async function verifyOtp(
  submitRootPath: string,
  phone: string,
  code: string
): Promise<void> {
  await axios.post(`${submitRootPath}/http/verifyotp`, { phone, code })
}
