<script>
  import { Separator, Caption, WidgetHeader } from '@qlean/ui-kit-web-svelte'
  import { Bus } from '@qlean/web-core'
  import axios from 'axios'

  import CommonWrapper from '../../components/CommonWrapper'
  import Disclaimer from '../../components/Disclaimer'
  import OtpVerification from '../../components/OtpVerification'

  import FirstStep from './FirstStep'

  export let defaultValues
  export let productSlug
  export let submitRootPath
  export let fields
  export let texts
  export let cN
  export let promocode
  // FIXME: костыль для зимних окон
  export let swapHeaderAndForm

  const { disclaimer, title, subTitle, buttonTitle } = texts

  let initialValues = { ...defaultValues }
  let showVerificationFields = false
  let formValues = { ...initialValues }
  let ids
  let commonError = null

  const COMMON_ERROR_MSG = 'Сервис временно недоступен, попробуйте позже'

  async function submitData(values, ids) {
    const { data } = await axios.post(`${submitRootPath}/http/form/submit`, {
      productSlug,
      values,
      ids,
      promocode,
    })

    return data
  }

  async function onSuccessPhone(values) {
    resetCommonError()
    Bus.emit('FORM_CREATE', { ids })

    try {
      if (defaultValues.phone === undefined) {
        formValues = { ...values }
        showVerificationFields = true
      } else {
        ids = await submitData(values)
        Bus.emit('FORM_FINISH', { ids })
      }
    } catch (err) {
      commonError = COMMON_ERROR_MSG
    }
  }
  async function onSuccessCode() {
    resetCommonError()

    try {
      await submitData(formValues, ids)

      Bus.emit('FORM_FINISH', { ids })
    } catch (err) {
      commonError = COMMON_ERROR_MSG
    }
  }

  function hideVerification() {
    resetCommonError()

    initialValues = formValues
    showVerificationFields = false
  }

  function onUnknownError(err) {
    try {
      // @fixme resolve optional chaining for svelte
      if (err.response.status === 429) {
        commonError = 'Слишком частые запросы SMS'
      } else {
        // @todo: log it
        commonError = COMMON_ERROR_MSG
      }
    } catch (e) {
      commonError = COMMON_ERROR_MSG
    }
  }

  function resetCommonError() {
    commonError = null
  }
</script>

<CommonWrapper {...$$restProps} {swapHeaderAndForm} {title} {subTitle} isTitleLarge={true} {cN}>
  <div class={cN('form')}>
    <Separator key={10} size="medium" />
    <div class={cN('form__row')}>
      <Separator key={10} size="medium" direction="horizontal" />
      {#if !showVerificationFields}
        <FirstStep
          onSuccess={onSuccessPhone}
          {onUnknownError}
          onChangeState={resetCommonError}
          {initialValues}
          {buttonTitle}
          {fields}
          showPhone={defaultValues.phone === undefined}
          {cN} />
      {:else}
        <OtpVerification
          onSuccess={onSuccessCode}
          {onUnknownError}
          onChangeState={resetCommonError}
          onRequestHide={hideVerification}
          canRequestHide={defaultValues.phone === undefined}
          phone={formValues.phone}
          {submitRootPath}
          {cN}
          fromForm={true} />
      {/if}
      <Separator key={10} size="medium" direction="horizontal" />
    </div>
    {#if commonError !== null}
      <Separator key={10} size="medium" />
      <Caption class={cN('form__common-error')}>{commonError}</Caption>
    {/if}
    <Separator key={10} size="medium" />
  </div>

  {#if !showVerificationFields}
    <Disclaimer {disclaimer} {cN} />
  {/if}

  {#if swapHeaderAndForm}
    <WidgetHeader
      {title}
      titleTag={$$restProps.titleTag}
      {subTitle}
      isTitleLarge={$$restProps.isTitleLarge}
      withMargin={$$restProps.withTopMargin} />
  {/if}
</CommonWrapper>
