import classNames from 'classnames/bind'
import css from '../../style.scss'

const cN = classNames.bind(css)
const blockName = 'button'

export const WIDTHS = {
  SMALL: 'small',
  MEDIUM: 'medium',
  WIDE: 'wide',
} as const

export const HEIGHTS = {
  SMALL: 'small',
  MEDIUM: 'medium',
} as const

export const BACKDROPS = {
  DARK: 'dark',
  LIGHT: 'light',
} as const

export type TCommonProps = {
  /** Является ли ссылкой */
  isLink: boolean
  /** Сделать кнопку округлой */
  rounded: boolean
  /** Ширина */
  width: ValueOf<typeof WIDTHS>
  /** Высота */
  height: ValueOf<typeof HEIGHTS>
  /** Включает тень */
  floating: boolean
}

export type TButtonProps = {
  /** Сделать неактивной */
  disabled: boolean
  /** Пометить "в процессе" */
  isSubmitting: boolean
}

export type TLinkProps = {
  /** Адрес ссылки */
  href: string
}

export function getClass({
  rounded,
  width,
  height,
  floating,
  className,
  isProcessing,
}: TCommonProps & { isProcessing: boolean; className: string }): string {
  // @ts-ignore
  return cN(
    blockName,
    {
      [`${blockName}--rounded`]: rounded,
      [`${blockName}--floating`]: floating,
      [`${blockName}--processing`]: isProcessing,
      [`${blockName}--height-${height}`]: true,
      [`${blockName}--width-${width}`]: true,
    },
    className
  )
}
