<script context="module">
  import { SIZES } from './utils'

  export { SIZES }
</script>

<script>
  import { Caption, Separator } from '@qlean/ui-kit-web-svelte'
  import {
    getContainerClass,
    getWrapperClass,
    getInputClass,
    getPlaceholderClass,
    getErrorClass,
    getPlaceholder,
  } from './utils'

  export let size = SIZES.MEDIUM
  export let withError = false
  export let placeholder = undefined
  export let disabled = false

  // по какой-то причине установка `value` посредством $$restProps перетирает значения инпута,
  // даже если значение переменной не изменилось (например, если снаружи отменили ввод буквы)
  $: placeholderToUse = getPlaceholder(placeholder, $$props.value)

  $: containerClassName = getContainerClass({ className: $$props.class || '' })
  $: inputClassName = getInputClass({ size, withError })
  $: placeholderClassName = getPlaceholderClass({ size, disabled })
</script>

<div class={containerClassName}>
  <div class={getWrapperClass({ disabled })}>
    {#if placeholderToUse !== undefined}
      <div class={placeholderClassName}>
        <span>{placeholderToUse[0]}</span>
        {placeholderToUse[1]}
      </div>
    {/if}

    <input
      on:click
      on:keydown
      on:input
      on:change
      on:search
      on:focus
      on:blur
      {...$$restProps}
      {disabled}
      class={inputClassName} />
  </div>

  {#if typeof withError === 'string'}
    <Separator key={5} size={Separator.SIZES.SMALL} />
    <Caption class={getErrorClass()}>{withError}</Caption>
  {/if}
</div>
