<script>
  import { onMount } from 'svelte'
  import { createForm } from 'final-form'
  import { ButtonPrimary, Text, Link, Input, Separator, GridColumn } from '@qlean/ui-kit-web-svelte'
  import { Bus, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS } from '@qlean/web-core'

  import Field from '../Field'
  import { requestOtp, verifyOtp } from '../../utils'

  export let onSuccess
  export let onUnknownError
  export let onRequestHide
  export let onChangeState = () => {}
  export let phone = undefined
  export let canRequestHide = true
  export let submitRootPath
  export let cN
  export let fromForm = false

  let otpTimeout
  let otpTimer
  let isSubmitting = false

  function getTimeLeast(date) {
    return 60 - Math.round((Date.now() - date) / 1000)
  }

  function startTimer(date = Date.now()) {
    clearTimeout(otpTimer)
    otpTimeout = getTimeLeast(date)
    window.localStorage.setItem('otpRequestTime', date)

    function next() {
      otpTimeout = getTimeLeast(date)

      if (otpTimeout > 0) {
        otpTimer = setTimeout(next, 1000)
      } else {
        otpTimeout = null
      }
    }

    next()
  }

  async function requestVerification(onMount = false) {
    if (onMount) {
      Bus.emit(USER_LOGIN_REQUEST, { fromForm })
      Bus.emit('OTP_SEND')
    } else {
      Bus.emit('OTP_RESEND')
    }

    onChangeState()
    isSubmitting = true

    try {
      startTimer()

      const response = await requestOtp(submitRootPath, phone)

      if (response) {
        console.log(response)
      }
    } catch (err) {
      onUnknownError(err)
    }

    isSubmitting = false
  }

  function autoSubmitOtp({ target }) {
    const { value } = target

    if (value.length !== 4) {
      return
    }

    target.blur()

    form.submit()
  }

  function requestChangePhone(e) {
    e.preventDefault()

    onRequestHide()
  }

  async function submitCode({ code }, _, callback) {
    onChangeState()
    isSubmitting = true

    try {
      await verifyOtp(submitRootPath, phone, code)
      window.localStorage.removeItem('otpRequestTime')

      Bus.emit(USER_LOGIN_SUCCESS)
      Bus.emit('OTP_SUCCESS')

      callback()
      await onSuccess()
    } catch (err) {
      if (err.response !== undefined && err.response.status === 401) {
        callback({ code: 'Неверный код' })
      } else {
        callback()
        onUnknownError(err)
      }
    }

    isSubmitting = false
  }

  const validate = ({ code }) => {
    const errors = {}
    if (code.length < 4) {
      errors.code = { value: code, message: 'Код должен содержать 4 цифры' }
    }

    return errors
  }

  const form = createForm({
    initialValues: { code: '' },
    onSubmit: submitCode,
    validate,
    validateOnBlur: true,
  })

  onMount(() => {
    const prevPhone = window.localStorage.getItem('phone')
    const otpRequestTime = window.localStorage.getItem('otpRequestTime')

    if (phone !== undefined) {
      window.localStorage.setItem('phone', phone)
    }

    if (prevPhone !== phone || otpRequestTime === null || getTimeLeast(otpRequestTime) <= 0) {
      requestVerification(true)
    } else if (otpTimeout !== null) {
      startTimer(otpRequestTime)
    }
  })
</script>

<form class={cN('form-otp-screen')} on:submit|preventDefault|stopPropagation={form.submit}>
  <GridColumn width={0} widthMiddle={1} />
  <GridColumn widthMiddle={10} class={cN('form-otp-screen__caption')}>
    <Separator key={10} size="medium" direction="horizontal" class={cN('form-otp-screen__gap')} />
    <Text>
      Введите код из SMS, который отправлен
      <span class={cN('form-otp-screen__phone')}>на {phone}.</span>
      {#if canRequestHide}
        <Link id="changeNumber" on:click={requestChangePhone}>Изменить номер</Link>
      {/if}
    </Text>
    <Separator key={10} size="medium" direction="horizontal" class={cN('form-otp-screen__gap')} />
  </GridColumn>
  <GridColumn width={0} widthMiddle={1} />
  <GridColumn>
    <Separator key={10} size={Separator.SIZES.SMALL} />
  </GridColumn>
  <GridColumn width={0} widthMiddle={3} />
  <GridColumn widthMiddle={3} class={cN('form-otp-screen__input-container')}>
    <Separator key={10} size="medium" direction="horizontal" class={cN('form-otp-screen__gap')} />
    <Field let:input let:meta name="code" {form}>
      <Input
        id="OtpInputArea"
        size={Input.SIZES.MEDIUM}
        withError={meta.touched && ((meta.error !== undefined && input.value === meta.error.value && meta.error.message) || (!meta.dirtySinceLastSubmit && meta.submitError))}
        maxlength="4"
        type="text"
        pattern="[0-9]*"
        inputmode="numeric"
        disalbed={isSubmitting}
        name="code"
        placeholder="____"
        value={input.value}
        disabled={isSubmitting}
        on:input={(e) => {
          onChangeState()
          input.change(e.target.value)
          autoSubmitOtp(e)
        }}
        on:focus={() => input.focus()}
        on:blur={() => input.blur()}
        autocomplete="off" />
    </Field>
    <Separator key={10} size="medium" direction="horizontal" class={cN('form-otp-screen__gap')} />
  </GridColumn>
  <GridColumn widthMiddle={3} class={cN('form-otp-screen__button-container')}>
    <Separator key={10} size="medium" direction="horizontal" class={cN('form-otp-screen__gap')} />
    <ButtonPrimary
      id="resendPasswordButton"
      {isSubmitting}
      disabled={otpTimeout !== null}
      on:click={() => requestVerification()}>
      {#if otpTimeout > 0}Получить новый код: {otpTimeout} c.{:else}Получить новый код{/if}
    </ButtonPrimary>
    <Separator key={10} size="medium" direction="horizontal" class={cN('form-otp-screen__gap')} />
  </GridColumn>
  <GridColumn width={0} widthMiddle={3} />
</form>
