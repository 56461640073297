<script>
  import { createForm } from 'final-form'
  import { GridColumn, ButtonPrimary, Input, Text, Separator } from '@qlean/ui-kit-web-svelte'

  import Field from '../../../components/Field'
  import { setFormattedPhone, onFocusPhone, validatePhone } from '../../../utils'

  export let onSuccess
  export let onUnknownError
  export let onChangeState = () => {}

  export let buttonTitle
  export let initialValues
  export let cN

  let isSubmitting = false

  async function submit(values, _, callback) {
    onChangeState()
    isSubmitting = true

    try {
      callback()
      await onSuccess(values)
    } catch (err) {
      callback()
      onUnknownError(err)
    }

    isSubmitting = false
  }

  function validate({ phone }) {
    return { ...validatePhone(phone) }
  }

  const form = createForm({
    initialValues,
    onSubmit: submit,
    validate,
    validateOnBlur: true,
  })
</script>

<form class={cN('form-phone-screen')} on:submit|preventDefault|stopPropagation={form.submit}>
  <GridColumn width={0} widthMiddle={1} />
  <GridColumn widthMiddle={10} class={cN('form-phone-screen__caption')}>
    <Separator key={10} size="medium" direction="horizontal" class={cN('form-phone-screen__gap')} />
    <Text>Введите свой номер телефона — мы отправим проверочный код</Text>
    <Separator key={10} size="medium" direction="horizontal" class={cN('form-phone-screen__gap')} />
  </GridColumn>
  <GridColumn width={0} widthMiddle={1} />
  <GridColumn>
    <Separator key={10} size={Separator.SIZES.SMALL} />
  </GridColumn>
  <GridColumn width={0} widthMiddle={3} />
  <GridColumn widthMiddle={3} class={cN('form-phone-screen__input-container')}>
    <Separator key={10} size="medium" direction="horizontal" class={cN('form-phone-screen__gap')} />
    <Field let:input let:meta name="phone" {form}>
      <Input
        size={Input.SIZES.MEDIUM}
        withError={meta.touched && meta.error !== undefined && input.value === meta.error.value && meta.error.message}
        maxlength="18"
        type="tel"
        name="phone"
        placeholder="+7 (___) ___-__-__"
        value={input.value}
        disabled={isSubmitting}
        on:input={(e) => setFormattedPhone(input, e.target.value)}
        on:focus={(e) => onFocusPhone(input, e.target.value)}
        on:blur={(e) => input.blur()}
        autocomplete="off" />
    </Field>
    <Separator key={10} size="medium" direction="horizontal" class={cN('form-phone-screen__gap')} />
  </GridColumn>
  <GridColumn widthMiddle={3} class={cN('form-phone-screen__button-container')}>
    <Separator key={10} size="medium" direction="horizontal" class={cN('form-phone-screen__gap')} />
    <ButtonPrimary {isSubmitting} type="submit">{buttonTitle}</ButtonPrimary>
    <Separator key={10} size="medium" direction="horizontal" class={cN('form-phone-screen__gap')} />
  </GridColumn>
  <GridColumn width={0} widthMiddle={3} />
</form>
