<script>
  export let name
  export let form

  let meta = {}
  let input = {}

  form.registerField(
    name,
    ({ blur, change, focus, value, ...fieldMeta }) => {
      meta = fieldMeta

      input = {
        name,
        blur,
        change,
        focus,
        value,
      }
    },
    {
      active: true,
      touched: true,
      modified: true,
      value: true,
      error: true,
      submitError: true,
      dirtySinceLastSubmit: true,
    }
  )
</script>

<slot {meta} {input} />
